@import 'node_modules/@dashboard-experience/mastodon/lib/theme.scss';

.sensitive-text-input-wrapper {
  position: relative;
  .cds--text-input__invalid-icon {
    display: none;
  }
}

.sensitive-text-input-hide-button {
  position: absolute !important;
  right: 0;
  top: 15px;
  width: 34px;
  height: 40px;
  z-index: 2;
  padding: 0 0 0 0 !important;
}

.sensitive-text-input-hide-button-tooltip {
  min-width: max-content;
}

#mastodon .mastodon-text-input .cds--text-input:hover {
  background-color: #ffffff;
}
