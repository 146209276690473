@import 'node_modules/@dashboard-experience/mastodon/lib/theme.scss';

#mastodon .matrix-verification-wrapper {
  .mastodon-container.container-official {
    margin-bottom: 0;
  }

  .mastodon-container.container-default {
    padding: 32px !important;
    margin-bottom: 16px !important;
  }

  .mastodon-container-col,
  .mastodon-container-row {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  .verification__wrapper--new-flow
    form
    .mastodon-container
    .mastodon-container-row {
    padding-bottom: 0;
  }

  .cds--date-picker {
    display: block !important;
    width: 100%; //required for responsive input line
  }
  .cds--date-picker__input {
    width: 100% !important; //required for responsive input line
  }

  .form-control-label {
    margin-top: 0px;
  }

  .form-group {
    margin-bottom: 0px;
    &.multiple-form-group {
      margin-bottom: 0;
      > .form-control-label {
        margin-top: 0;
      }
    }
  }

  .aqua-04 {
    color: $aqua-04;
  }

  .html-field {
    margin: 0px 20px 0px 20px;
  }

  .remove-document-upload {
    height: 17px;
  }

  .matrix-document-upload-removal-button {
    border-color: white;
    background-color: white;
    border-width: 0px;
  }

  .matrix-address-form-group {
    width: 50%;

    .row {
      margin-top: 20px;
    }

    .invalid-feedback {
      margin-top: 0px;
    }

    > .form-control-label {
      font-weight: 900;
    }
  }

  .matrix-label-style {
    .cds--label {
      font-size: 14px;
      line-height: inherit;
      font-weight: bold;
    }
  }

  // Override mastodon padding on mobile
  @media (max-width: 675px) {
    .mastodon-container
      .mastodon-container-row
      .mastodon-container-col.form-group-col {
      padding-right: 0;
    }

    #mastodon .ssnConfirmation {
      margin-top: 20px;
    }
  }
}
