.fsp-content .fsp-select-labels {
  background-size: 60px 60px;
  background-image: url('../images/icons/fileadd-file-folder-extension-tag.svg');
}

.fsp-content .fsp-select-labels.active {
  background-image: url('../images/icons/fileadd-file-folder-extension-tag-active.svg');
}

.fsp-picker__footer {
  display: none !important;
}

// https://github.com/filestack/filestack-js/issues/335#issuecomment-742494811
.fsp-notifications__container {
  position: fixed !important;
}

@media (max-width: 991px) {
  .fsp-modal__sidebar {
    display: none !important;
  }

  .fsp-mobile-menu {
    display: none !important;
  }

  .fsp-content .fsp-drop-area {
    top: calc(50% - 100px);
    bottom: auto;
    width: 200px;
    height: 200px;
    padding: 0.25rem;
  }
}
