// @use './theme.scss';
// @use './mixins.scss';
@use 'node_modules/@dashboard-experience/mastodon/lib/theme.scss';
@use './mixins.scss';

#mastodon {
  .eyebrow {
    color: theme.$ui-text-primary-light;
    font: {
      size: .75rem;
      weight: 600;
    }
    letter-spacing: .0375rem;
    text-transform: uppercase;

    @media (min-width: 48rem) {
      font-size: .875rem;
      letter-spacing: .044rem;
    }
  }

  .highlighted-text {
    color: theme.$ui-text-primary-light;
    font-size: .875rem;
  }

  .p4 { // 12px
    @include mixins.p(.75rem, .975rem);
  }

  .p3 { // 14px
    @include mixins.p(.875rem, 1.31rem);
  }

  .p2 { // 16px
    @include mixins.p(1rem, 1.5rem);
  }

  .p1 { // 20px
    @include mixins.p(1.25rem, 1.75rem);
  }

  // Applicable to theme.$fonts-legacy: '"Public Sans",
  .italic {
    font-style: italic;
  }

  .medium {
    font-weight: 500;
  }

  .semi-bold {
    font-weight: 600;
  }

  .bold {
    font-weight: 700;
  }
}
