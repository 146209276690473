#summary-container {
  h2,h3,h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
  }

  h2.summary-situation {
    margin: 0;
  }

  h3.summary-reason-code {
    margin-top: 0;
  }

  h4.summary-subreason-text {
    font-weight: bold;
    display: inline-block;
    margin: 0;
  }

  .summary-subreason-detail-text {
    font-style: italic;
    margin-left: 0.3em;
  }
}

div.add-an-error-container {
  display: flex;
  justify-content: center;
}