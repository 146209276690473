.success-footer-title {
  position: relative;
  line-height: 22px !important;
  margin-bottom: 20px !important;
  padding-bottom: 16px;

  &:after {
    content:'';
    background-color: #001730;
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 4px;

    @media (max-width: 767px) {
      width: 30px;
    }

    @media (min-width: 768px) {
      width: 60px;
    }

    border-radius: 2px;
  }
}

.success-footer{
  ol {
    list-style: none;
    counter-reset: steps;
    list-style-position: outside;
    padding-left: 32px;
  }

  ol li {
    counter-increment: steps;
    margin-bottom: 20px;
  }

  ol li::before {
    content: counter(steps);
    margin-right: 8px;
    margin-left: -32px;
    background: #009BB5;
    color: white;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-grid;
    place-items: center;
    line-height: 1.2em;
  }
}
