@import 'node_modules/@dashboard-experience/mastodon/lib/theme.scss';

.iconTextList {
  margin-bottom: 23px !important;

  :global h3 {
    font-family: $fonts-legacy !important;
    font-size: 16px !important;
    line-height: 24px !important;
    margin-bottom: 24px !important;
  }

  :global {
    .mastodon-key-value-line-item {
      display: grid !important;
      grid-template-columns: 44px 1fr;
      margin-top: 20px;
      &:first-child {
        margin-top: 0;
      }
      .key {
        color: $navy-03 !important;
        .mastodon-icon {
          color: inherit;
          fill: currentColor;
        }
      }
      .value {
        font-family: $fonts-legacy;
        font-size: 14px;
        line-height: 20px;
        color: $navy-03 !important;
        a {
          text-decoration: underline;
          color: $navy-01 !important;
        }
      }
    }
  }
}
