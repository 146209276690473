@import 'node_modules/@dashboard-experience/mastodon/lib/theme.scss'

.language-toggle
  position: relative
  &.language-toggle--opened
    > .header__menu-item.secondary.mastodon-button.cds--btn.cds--btn--sm.cds--btn--primary
      border-right: 4px solid $aqua-01 !important
      background-color: $navy-01 !important
      &:hover,
      &:focus
        border-right: 4px solid $aqua-01 !important
        background-color: $navy-01 !important
      @media (min-width: 768px)
        border-right: 1px solid $white !important
        &:hover,
        &:focus
          border-right: 1px solid $white !important
  .language-toggle__options[role='menu']
    width: calc(100% - 63px)
    display: grid !important
    grid-template-columns: 100%
    background-color: $navy-03
    margin-left: 63px
    margin-top: 4px
    border-left: 2px solid $aqua-01
    @media (min-width: 768px)
      top: calc(100% + 10px)
      position: absolute
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25)
      border-radius: 2px
      border-left: 0
      width: 100%
      margin-left: 0
    .mastodon-button.secondary.cds--btn.cds--btn--sm.cds--btn--primary
      color: $white !important
      max-width: 100%
      background-color: transparent !important
      margin: 0 !important
      border: 0 !important
      text-align: center !important
      font-family: $fonts-legacy !important
      font-weight: 400 !important
      display: block !important
      padding: 13px !important
      border-right: 4px solid transparent !important
      border-radius: 0 !important
      &.selected
        background-color: rgba(0, 0, 0, .4) !important
      &:hover,
      &:focus
        outline: none
        background-color: $navy-01 !important
        border-color: $aqua-01 !important
