@use 'node_modules/@dashboard-experience/mastodon/lib/theme.scss';

@mixin body-text {
  font-family: theme.$fonts-legacy, theme.$fonts-ui;
  font-style: normal;
  font-weight: normal;
}

@mixin p($size, $line-height) {
  font-size: $size;
  line-height: $line-height;
}

@mixin small-body-text {
  @include body-text();
  @include p(.875rem, 1.31rem); // p3
}

@mixin medium-body-text {
  @include body-text();
  @include p(1rem, 1.5rem); // p2
}

@mixin large-body-text {
  @include body-text();
  font-size: 18px;
  line-height: 32px;
}

@mixin extra-large-body-text {
  @include body-text();
  @include p(1.25rem, 1.75rem); // p1
}

@mixin hero-body-text {
  @include body-text();
  font-size: 24px;
  line-height: 40px;
}

@mixin input-text {
  font-family: theme.$fonts-legacy, theme.$fonts-ui;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: normal;
  // Typography in Figma shows dark-gray, but input shows cool-black-100
  color: theme.$ui-grey-700;
}

@mixin small-input-text {
  @include input-text();
  color: theme.$ui-text-primary-light;
  font: {
    size: .75rem;
    weight: 600;
  }
  line-height: 1;
  letter-spacing: 0;
}

@mixin primary-button-text {
  font: {
    family: theme.$fonts-legacy, theme.$fonts-ui;
    size: 14px;
    weight: 700;
  }
  line-height: 1;
  letter-spacing: .01rem;
  text-decoration: none;
}

@mixin placeholder-text {
  color: theme.$ui-grey-500;
  font-style: italic;
  font-size: 14px;
}

@mixin font-face($font-family, $font-path, $font-format, $font-weight: normal, $font-style: normal) {
  @font-face {
    font-family: '#{$font-family}';
    src: url('#{theme.$font-base-url}/#{$font-path}') format('#{$font-format}');
    font-weight: $font-weight;
    font-style: $font-style;
  }
}

@mixin input-border-bottom-base {
  border-bottom: 1px solid theme.$cool-black-50;
}

@mixin input-border-bottom {
  border-bottom: 1px solid theme.$ui-navy-600 !important;
}

@mixin input-base-styles {
  @include input-text();
  @include input-border-bottom-base;
  background-color: theme.$ui-grey-0;
  outline: none;
}

@mixin input-focused-styles {
  @include standard-shadow();
  @include input-border-bottom;
  outline: none;
}

@mixin field($field-class) {
  margin-bottom: 0.5rem;

  // This seems like it should be able to be placed in the field styles, but I can't get it to select correctly
  [data-invalid='true'] {
    outline: none;

    .#{$field-class} {
      border-bottom: 1px solid theme.$danger-red-100;
    }
  }

  .#{$field-class}__invalid-icon {
    z-index: 1;
    fill: theme.$danger-red-100;
  }

  .#{$field-class} {
    @include input-base-styles;

    &:disabled,
    &[aria-disabled='true'] {
      background-color: theme.$ui-grey-100;
      border-bottom: 1px solid theme.$ui-grey-400;
    }

    &:focus {
      @include input-focused-styles;
    }

    &:hover {
      background-color: theme.$ui-grey-100;
    }
  }

  .#{$field-class}--sm {
    height: theme.$small-field-height;
  }

  .#{field-class} {
    height: theme.$field-height;
  }

  .#{$field-class}--lg {
    height: theme.$large-field-height;
  }

  .#{$field-class}--xl {
    height: theme.$xlarge-field-height;
  }
}

@mixin button($background-color, $border-color, $color: theme.$ui-grey-0) {
  @include primary-button-text();
  @include standard-button-shadow();

  background-color: $background-color;
  border: 1px solid $border-color;
  border-radius: 4px;
  color: $color;
  min-height: 36px;

  svg {
    fill: $color;
  }

  padding: 8px 14px;
  margin: 0rem 0.5rem;

  &:focus,
  &:hover {
    outline: none;
  }

  &[disabled]:not(.tertiary),
  &.disabled:not(.tertiary) {
    @include button-default-disabled();
  }

  &:first-child {
    margin-left: 0rem;
  }

  &:last-child {
    margin-right: 0rem;
  }

  &:only-of-type {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  &.disabled {
    cursor: not-allowed;
  }
}

@mixin button-default-disabled {
  color: rgba(theme.$ui-grey-900, 48%);
  background-color: theme.$ui-grey-200;
  border-color: theme.$ui-grey-200;
  box-shadow: unset;
  svg {
    fill: rgba(theme.$ui-grey-900, 48%);
  }
}

@mixin button-to-link {
  color: theme.$ui-navy-600;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0px;
  margin: 0px;
  text-decoration: none;
  @include small-body-text();

  &:hover {
    text-decoration: underline;
  }
}

@mixin no-shadow {
  box-shadow: none;
}

@mixin light-shadow {
  box-shadow: 0rem 0.25rem 1.5rem rgba(22, 22, 29, 0.08);
}

@mixin standard-shadow {
  box-shadow: 0rem 0.25rem 0.5rem rgba(22, 22, 29, 0.1);
}

@mixin standard-button-shadow {
  box-shadow: 0px 2px 4px -2px rgba(72, 82, 94, 0.14);
}

@mixin heavy-shadow {
  box-shadow: 0rem 0.125rem 0.375rem rgba(22, 22, 29, 0.3);
}

@mixin box-shadow-4 {
  box-shadow: 0rem 0rem 1rem rgba(theme.$ui-text-primary-light, 0.18);
}

@mixin checkbox {
  .cds--checkbox-label {
    line-height: 1.25rem;
    min-height: 1.125rem;
    padding: 0 0 0 1rem;

    &::before {
      border-color: theme.$ui-grey-300;
      border-radius: 0.125rem;
      margin: 0.125rem 0;
      top: 0;
    }
    
    &::after {
      background-color: transparent;
      background-position: 50%;
      background-size: 0.75rem 0.75rem;
      border: 0;
      border-radius: 0.125rem;
      height: 1rem;
      left: 0;
      margin: 0.125rem 0;
      top: 0;
      transform: unset;
      width: 1rem;
    }
    
    .cds--checkbox-label-text {
      color: theme.$ui-text-primary-light;
      padding-left: 0.75rem;
    }
    
    &:hover {
      &::before {
        box-shadow: 0 0.125rem 0.375rem -0.125rem rgba(155, 161, 168, 0.2);
      }

      &::after {
        background-image: url('data:image/svg+xml,<svg width="12" height="12" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.53 3.47a.75.75 0 0 1 0 1.06l-4 4a.75.75 0 0 1-1.06 0l-2-2a.75.75 0 0 1 1.06-1.06L5 6.94l3.47-3.47a.75.75 0 0 1 1.06 0Z" fill="%23E1E6EB"/></svg>');
      }
    }
  }
  .cds--checkbox {
    &:disabled {
      + .cds--checkbox-label {
        &::before {
          background: theme.$ui-grey-100;
        }

        &::after {
          background-image: none;
        }

        .cds--checkbox-label-text {
          color: theme.$ui-text-disabled-light;
        }
        
        &:hover {
          &::before {
            background: theme.$ui-grey-100;
            box-shadow: none;
          }
        }
      }
    }
    &:checked,
    &:indeterminate {
      &:disabled {
        + .cds--checkbox-label {
          &::before {
            background: theme.$ui-text-disabled-light;
          }
          
          &:hover {
            &::before {
              background: theme.$ui-text-disabled-light;
            }
          }
        }
      }
      + .cds--checkbox-label {
        &::before {
          background: theme.$ui-link-primary-light-default;
          border-color: theme.$ui-link-primary-light-default;
        }
      }
      
      &:hover {
        + .cds--checkbox-label {
          &::before {
            background: theme.$ui-link-primary-light-hover;
          }

          &::before {
            background-image: none;
          }
        } 
      }
    }
    
    &:checked {
      + .cds--checkbox-label {
        &::after {
          background-image: url('data:image/svg+xml,<svg width="12" height="12" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.53 3.47a.75.75 0 0 1 0 1.06l-4 4a.75.75 0 0 1-1.06 0l-2-2a.75.75 0 0 1 1.06-1.06L5 6.94l3.47-3.47a.75.75 0 0 1 1.06 0Z" fill="%23fff"/></svg>');
        }
      }
    }

    &:indeterminate {
      + .cds--checkbox-label {
        &::after {
          background-image: url('data:image/svg+xml,<svg width="12" height="12" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 6A.75.75 0 0 1 3 5.25h6a.75.75 0 0 1 0 1.5H3A.75.75 0 0 1 2.25 6Z" fill="%23fff"/></svg>');
        }
      }
    }

    &:focus {
      + .cds--checkbox-label {
        &::before {
          outline: none;
          box-shadow: 0 0 0 0.063rem theme.$ui-grey-0, 0 0 0 0.125rem theme.$ui-navy-100, 0 0 0 0.218rem theme.$ui-navy-200;
        }
      }
    }
  }
}

@mixin container(
  $background-color: theme.$ui-grey-0,
  $border-color: theme.$ui-grey-200,
  $padding: 1.5rem
) {
  padding: $padding;
  margin-bottom: 1.5rem;

  border: 1px solid $border-color;
  border-radius: 4px;

  background-color: $background-color;
}

@mixin searchInput($padding: theme.$field-height) {
  .cds--search-input {
    height: $padding;
    padding: 0 $padding;
  }

  .cds--search-magnifier-icon {
    left: calc(($padding - 1rem) / 2);
  }

  .cds--search-close {
    width: $padding;
    height: $padding;
  }
}

@mixin highlighted {
  border-bottom: 0.0625rem dotted theme.$ui-grey-700;
  &:hover {
    border-bottom-color: theme.$ui-navy-600;
  }
}

@mixin tooltipPopover {
  .cds--popover-content {
    max-width: 13rem;
    background: theme.$ui-grey-0;
    border-radius: 4px;
    @include box-shadow-4();
    @include small-body-text();
    color: theme.$ui-grey-700;
    margin: 0px;
  }

  .cds--popover-caret {
    background: theme.$ui-grey-0;
  }
}

@mixin notification($class) {
  .cds--#{$class}-notification {
    margin-top: 1rem;
    color: theme.$ui-text-primary-light;
    border-color: theme.$ui-text-primary-light;

    &:not(.cds--#{$class}-notification--low-contrast) {
      // make button look like a regular
      .cds--#{$class}-notification__action-button.cds--btn--ghost {
        color: theme.$ui-text-primary-light;
        border-color: transparent;
      }
    }

    .cds--#{$class}-notification__close-icon {
      fill: theme.$ui-text-primary-light;
    }
    .cds--#{$class}-notification__text-wrapper {
      width: 100%;
    }

    .cds--#{$class}-notification__action-button {
      border-color: theme.$ui-text-primary-light;
      color: theme.$ui-text-primary-light;
      &:hover {
        color: theme.$ui-grey-700;
        background: none;
      }
      &:focus {
        background: none;
      }
    }

    .cds--#{$class}-notification__title,
    .cds--#{$class}-notification__subtitle {
      color: theme.$ui-text-primary-light;
    }
    .cds--inline-notification__title,
    .cds--inline-notification__subtitle {
      width: 100%;
    }
  }

  .cds--#{$class}-notification--info {
    border-color: theme.$ui-text-primary-light;
    background-color: theme.$ui-grey-300;

    .cds--actionable-notification__icon,
    .cds--toast-notification__icon,
    .cds--inline-notification__icon {
      fill: theme.$ui-text-primary-light;
    }
  }

  .cds--#{$class}-notification--warning {
    border-color: theme.$ui-yellow-400;
    background-color: theme.$ui-yellow-100;

    .cds--actionable-notification__icon,
    .cds--toast-notification__icon,
    .cds--inline-notification__icon {
      fill: theme.$ui-yellow-400;
    }
  }

  .cds--#{$class}-notification--error {
    border-color: theme.$danger-red-100;
    background-color: theme.$ui-orange-100;

    .cds--actionable-notification__icon,
    .cds--toast-notification__icon,
    .cds--inline-notification__icon {
      fill: theme.$danger-red-100;
    }
  }

  .cds--#{$class}-notification--success {
    border-color: theme.$ui-green-600;
    background-color: theme.$ui-green-100;

    .cds--actionable-notification__icon,
    .cds--toast-notification__icon,
    .cds--inline-notification__icon {
      fill: theme.$ui-green-600;
    }
  }
}
