.center {
  text-align: center;
}

.heading {
  color: #043F93;
}

.sub-heading {
  font-weight: 600;
  padding-bottom: 1em;
}

.instructions {
  padding: 1.5em 9.5em;
}

.line {
  border-top: 1px solid #DFDFE0;
  width: 65%;
  display: inline-block;

}