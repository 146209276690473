.footer {
  background: rgb(247, 249, 250);
  font-weight: 300;
  font-size: 13px;
  padding: 30px;
  padding-bottom: 90px;
}

.footer .checkr-logo {
  margin-bottom: 15px;
}

.footer a {
  color: #666;
  text-decoration: underline;
  margin: 0 5px;
}

.footer .reCaptcha {
  padding-top: 8px;
}

.footer .reCaptcha, .footer .reCaptcha a {
  color: #999;
}

@media (min-width: 768px) {
  .footer {
    padding-bottom: 30px;
  }
}
