.styled {
    padding: 5px 138.5px;
    display: flex;
    flex-direction: column;
    row-gap: 23px;
}

.centerHeader {
    text-align: center;
}
