#provide-information-subreasons > .provide-information-subreason-heading {
  margin-top: 0;
  margin-bottom: 0 ;
}

div#provide-information-subreasons {
  margin-bottom: 0.5rem;
}

div#provide-information-instructions-note {
  margin-bottom: 1em;
}

div#provide-information-upload-instructions-note {
  margin-top: 2em;
}

button#upload-btn {
  margin-bottom: 1em;
}

#mastodon h3:not(:only-child) {
  margin-bottom: 0.1rem;
}

.grey-text {
  color: #6c757d;
}

.cds--accordion__content {
  padding: 0;
}

.cds--accordion__heading:focus::before {
  border:none
}

.cds--accordion__title {
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  margin: 0;
  padding-bottom: 15px;
}

.cds--accordion__item {
  border-top: none;
}

.task-pending {
  font-size: 14px;
  color: #45454A;
  display: flex;
  align-items:center;
  column-gap: 4px;
}
.task-complete {
  font-size: 14px;
  color: green;
  display: flex;
  align-items:center;
  column-gap: 4px;
}

.accordion-title {
  display: flex;
  column-gap: 20px;
}