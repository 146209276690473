@import 'node_modules/@dashboard-experience/mastodon/lib/theme.scss';

#mastodon .reports-filter {
  padding-top: 16px;
  .reports-filter__label {
    color: $cool-black-90;
    font-weight: 500;
    padding: 8px 0;
    font-size: 12px;
  }
  .cds--radio-button-wrapper {
    .cds--radio-button {
      & + .cds--radio-button__label {
        background-color: $background-frost;
        font-size: 14px;
        height: 24px;
        line-height: 18px;
        display: inline-block;
        border-radius: 12px;
        padding: 2px 12px 3px;
        color: $checkr-blue-80;
        transition: .15s;
        &:hover {
          background-color: $checkr-blue-30;
          box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
        }
      }
      &:checked {
        & + .cds--radio-button__label {
          background-color: $checkr-blue-80;
          color: $white;
          .cds--radio-button__appearance {
            display: none;
          }
          &:hover {
            box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
            background-color: $checkr-blue;
          }
        }
        &:focus {
          & + .cds--radio-button__label {
            box-shadow: 0px 0px 0 1px $white, 0px 0px 0 3px $checkr-blue, 0px 1px 1px 3px rgba(0, 0, 0, 0.25);
          }
        }
      }
      &:focus {
        & + .cds--radio-button__label {
          box-shadow: 0px 0px 0 1px $white, 0px 0px 0 3px $checkr-blue, 0px 1px 1px 3px rgba(0, 0, 0, 0.25);
        }
      }
      &:active {
        & + .cds--radio-button__label {
          box-shadow: none !important;
        }
      }
    }
  }
  .cds--radio-button-wrapper.-clicked {
    .cds--radio-button {
      &:checked:focus,
      &:focus {
        & + .cds--radio-button__label {
          box-shadow: none;
        }
      }
    }
  }
  .cds--radio-button__appearance {
    display: none;
  }
  .mastodon-radio-button {
    margin-right: 8px;
  }
}
