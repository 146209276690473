@import 'node_modules/@dashboard-experience/mastodon/lib/theme.scss';

$candidate-real-width: 600px;
$candidate-max-width: $candidate-real-width - 26px;

#mastodon {
  .candidate-landing-page {
    font-family: $fonts-legacy;
    .candidate-header {
      max-width: 100%;
      .mastodon-container {
        padding: 32px 18px 8px;
        max-width: $candidate-max-width;
        margin-bottom: 0;
        h2 {
          font-size: 48px;
          line-height: 100%;
          margin-bottom: 1.15rem;
        }
        p {
          font-size: 20px;
          line-height: 2.3rem;
          font-weight: 300;
        }
      }
    }
    .candidate-body {
      background-color: $white;
      max-width: 100%;
    }
    .mastodon-container.candidate-main {
      padding: 0px 18px;
      max-width: $candidate-max-width;
      margin: 0 auto;
      .cards-list {
        padding-top: 24px;
        padding-inline-start: 0;
        li {
          list-style-type: none;
        }
      }
      .mastodon-grid-col {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .candidate-footer.mastodon-container.container-transparent {
      padding: 14px 18px 5rem;
      margin-bottom: 0;
      max-width: $candidate-max-width;
      .mastodon-divider {
        margin: 0 auto;
      }
      .candidate-footer-container {
        border-top: 1px solid #CBD2DB;
        padding: 24px 0 0;
        h2 {
          margin: 0;
          font-size: 14px;
          color: #001730;
        }
        p {
          margin: .29rem 0;
          line-height: 1.15rem;
        }
        .faq-help-center-message {
          margin-top: 1rem;
          font-style: italic;
        }
        .faq-subtitle {
          margin-bottom: 1rem;
        }
      }
    }
  }
}
