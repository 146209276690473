@import 'node_modules/@dashboard-experience/mastodon/lib/theme.scss';

.success-icon {
  width: 18px;
  svg {
    fill: $success-green-100 !important;
    width: 16px !important;
  }
}

.remove-doc {
  padding-left: 1rem;

  :hover {
    cursor: pointer;
  }
}

.remove-icon {
  fill: $danger-red-100 !important;
  width: 16px !important;
  height: 16px !important;
  vertical-align: text-bottom !important;
}
