@import 'node_modules/@dashboard-experience/mastodon/lib/theme.scss';

.top-panel-style {
  padding-bottom: 0 !important;
  margin-bottom: 0!important;
}
.ssn-reentry__input {
  .cds--label {
    display: block;
    .mastodon-icon {
      height: 18px;
      width: 18px;
      fill: $aqua-03;
      margin: -5px 0 0 4px;
      vertical-align: middle;
    }
  }
}
