@import 'node_modules/@dashboard-experience/mastodon/lib/theme.scss';

@media (min-width: 576px) {
  .modal-dialog {
    margin-top: 110px;
  }
}

.modal-backdrop.show {
  opacity: 0.6;
}

.modal-dialog {
  box-shadow: 0 0 15px rgba(0,0,0,0.3);
}

.modal-header {
  background-color: $checkr-blue;
  color: $white;
}

.modal-title {
  font-weight: 300;
}

.close {
  color: $white;
  opacity: 1;
}

.close:hover {
  color: $white;
}

input[type='checkbox'] {
  margin-right: 5px;
}

textarea {
  resize: none;
}

.form-question {
  font-weight: bold;
}

.dispute-item {
  border: 1px solid #ddd;
  overflow-wrap: break-word;
}

.breadcrumb-modal .text-muted {
  margin: 0 20px;
}
