.icon-panel2-h1 {
  color: #009bb5;
  font-size: 32px;
  margin-bottom: 0;
  font-weight: 700;
  line-height: 39px;
}

.top-panel-style {
  margin-bottom: 42px !important;
  padding-bottom: 84px;
}

.top-div-style {
  padding: 0 20px;
}
