@import '@dashboard-experience/mastodon/lib/theme.scss';

.form-group {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif ;
  .form-content {
    padding-bottom:16px;
    span {
      padding-right: 16px;
    }
  }

  h3 {
    font-size: 14px;
  }

  .form-comment {
    font-size: 14px;
    white-space: pre-line;
  }
}

.v1-success-block {
  display: flex;
  align-items: center;
  font-size: 14px;

  svg,
  span {
    vertical-align: middle;
    fill: $success-green-100;
    margin-right: 5px;
  }
}

.text-aqua-04 {
  color: $aqua-04;
}

.matrix-document-upload-details-ul {
  li {
    color: $cool-black-70;
  }
}
