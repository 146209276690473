@import 'node_modules/@dashboard-experience/mastodon/lib/theme.scss';

#mastodon {
  .report__wrapper {
    .main-container {
      margin-top: 0;
    }
    .report-nav {
      height: 106px;
      padding: 18px 0 48px;
      .nav-list {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 0 auto;
        max-width: 950px;
        padding: 0;
        .nav-list--item {
          height: 40px;
          display: inline-block;
          .mastodon-link {
            line-height: 17px;
            font-size: 14px;
            padding: 8px 16px 8px 0;
            color: $checkr-blue !important;
            border: 0;
            border-radius: 2px;
          }
        }
        .mastodon-icon {
          display: inline-block;
          margin: -3px 0 -3px -7px;
          height: 24px;
          width: 24px;
          fill: #000;
        }
      }
    }
    &.report__wrapper--new-flow {
      .report-nav {
        height: auto;
        padding: 15px 0;
        margin-bottom: 42px;
        .nav-list {
          margin: 0;
          .nav-list--item {
            .mastodon-link {
              border: 1px solid $checkr-blue;
              height: 40px;
              padding: 11px 13px;
              line-height: 16px;
              font-size: 14px;
              .mastodon-icon {
                margin: 0;
                height: 16px;
                width: 16px;
                fill: $checkr-blue;
              }
            }
          }
        }
      }
    }
    .report__header {
      position: relative;
      margin-bottom: 28px;
      color: $navy-04 !important;
      padding-bottom: 16px;
      .report--account-name {
        font-weight: 700;
        font-size: 30px;
        line-height: 37px;
        margin: 0 0 8px;
        &.--prev-view {
          font-size: 30px;
          font-weight: 700;
          line-height: 37px;
        }
      }
      &.report__header--status {
        .report--status {
          margin: 0;
        }
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 73px;
          height: 4px;
          background-color: $navy-04;
          border-radius: 2px;
        }
      }
    }
  }
}
