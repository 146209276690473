#mastodon {
  .report-list--header {
    .report-list-header--summary {
      padding: 27px 0 6px;
      border-bottom: 1px solid #CBD2DB;
      justify-content: space-between;
      display: flex;
      > {
        h2:only-child,
        h2:not(:only-child),
        p {
          margin: 0;
        }
        h2 {
          font-size: 20px;
          color: #001730;
        }
        .action-count {
          line-height: 32px;
          .action-count--number {
            display: inline-block;
            flex-grow: 0;
            flex-shrink: 0;
            width: auto;
            padding: 0 5px;
            height: 16px;
            background-color: #CD2C1D;
            color: #fff;
            text-align: center;
            line-height: 16px;
            border-radius: 8px;
            font-size: 12px;
            font-weight: bold;
            margin-right: 3px;
            vertical-align: baseline;
          }
        }
      }
    }
  }
}
