.styled {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    background-color: white;

    ol {
        padding-left: 0;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        border-bottom: 1px solid #bbb;
        margin-bottom: 0;
    }

    li {
        display: inline;

        &:last-child {
            font-weight: 600;
        }
    }

    .breadcrumb-divider:not(:last-child)::after {
        content: ' • ';
    }
}
