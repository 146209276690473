@import 'node_modules/@dashboard-experience/mastodon/lib/theme.scss';

.cds--tag.report-status {
  flex-grow: 0;
  flex-shrink: 0;
  border: 0;
  padding: 15px 4px 15px 12px;
  margin: -12px 0;
  background-color: transparent;
  cursor: pointer;
  border-radius: 5px;
  background-color: 0;
  span {
    height: 21px;
    font-size: 12px;
    line-height: 12px;
    padding: 3px 6px;
    font-weight: 300;
    font-size: 14px;
    color: $cool-black-100;
  }
  &.status-action-required,
  &.status-consider {
    span {
      border-color: currentColor;
      font-weight: 600;
      font-size: 12px;
      border-radius: 16px;
      border-width: 1px;
      border-style: solid;
    }
  }
  &.status-action-required {
    span {
      color: #CD2C1D;
    }
  }
  &.status-consider {
    span {
      color: #576C82;
    }
  }
}
.mastodon-icon {
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  color: $cool-black-100;
  fill: $cool-black-100;
  margin-right: -16px;
}
