@import 'node_modules/@dashboard-experience/mastodon/lib/theme.scss';

#mastodon {
  .mastodon-container.panel.container-default {
    border-radius: 4px;
    padding: 0;
    margin: 23px 0;
    .panel__header {
      border-radius: 4px 4px 0 0;
      border-bottom: 1px solid;
      color: $navy-04;
      padding: 6px 12px 6px 8px;
      display: flex;
      flex-direction: row;
      .panel__title {
        h3 {
          font-size: 14px;
          line-height: 20px;
          font-family: $fonts-legacy;
          .mastodon-icon {
            width: 14px;
            height: 14px;
            display: inline-block;
            margin: 0 5px -2px 0;
            vertical-align: baseline;
          }
        }
        .panel__tag {
          flex-grow: 1;
          flex-shrink: 1;
        }
      }
      .panel__tag {
        flex-grow: 0;
        flex-shrink: 0;
      }
    }
    .panel__body {
      padding: 18px 28px 16px;
      color: $cool-black-100;
      font-family: $fonts-legacy;
      font-size: 14px;
      line-height: 20px;
      a {
        text-decoration: underline;
      }
      p {
        margin: 0;
      }
      .panel__button {
        margin: 12px 0;
      }
      p + .panel__eta {
        margin-top: 12px;
      }
    }
    &.panel--warning {
      border: 1px solid $orange-accent-01;
      .panel__header {
        border-color: $orange-accent-01;
        background-color: #FFEFEC;
        .panel__title {
          h3 {
            font-weight: 500;
          }
        }
        .mastodon-icon {
          fill: $orange-accent-01;
        }
      }
      .panel__eta {
        font-weight: bold;
      }
    }
    &.panel--caution {
      border: 1px solid $orange-02;
      .panel__header {
        border-color: $orange-02;
        background-color: $caution-yellow-bg;
        .panel__title {
          h3 {
            font-weight: 400;
          }
        }
        .mastodon-icon {
          fill: $navy-04;
        }
      }
    }
    &.panel--missing-info {
      margin-bottom: 96px;
    }
  }
}
