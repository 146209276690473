.file-type {
  color: #999 !important;
}

.file-uploaded {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.file-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.remove-doc:hover {
  cursor: pointer;
}
