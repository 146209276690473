@import 'node_modules/@dashboard-experience/mastodon/lib/theme.scss';

#mastodon {
  .mastodon-tile-option {
    &.report-card {
      position: relative;
      padding: 0;
      border-radius: 6px;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .25);
      margin-bottom: 14px;
      padding: 12px 20px 16px;
      border-color: transparent;
      .mastodon-tile-option-main {
        .mastodon-tile-option-title {
          margin: 16px 0 2px;
          font-size: 16px;
          line-height: 24px;
          font-weight: 600;
          flex-shrink: 1;
          flex-grow: 1;
          color: $cool-black-100;
        }
        .mastodon-tile-option-rightInfo {
          flex-shrink: 0;
          flex-grow: 0;
        }
        .report-card--body {
          padding: 0 12px 29px 20px;
        }
      }
      .mastodon-tile-option-detail {
        display: block;
        .mastodon-key-value-list {
          padding-bottom: 14px;
          .mastodon-key-value-line-item {
            & + .mastodon-key-value-line-item {
              padding-top: 2px;
            }
            .key,
            .value {
              display: inline;
            }
            .key {
              font-weight: 400;
              color: $cool-black-100;
              padding-right: 3px;
            }
            .value {
              font-weight: 300;
              color: $cool-black-100;
            }
          }
          &.footer-item {
            padding-bottom: 0;
            .mastodon-key-value-line-item {
              .key,
              .value {
                font-weight: 300;
                font-style: italic;
              }
            }
          }
        }
      }
      &:before {
        position: absolute;
        left: 12px;
        top: 16px;
        width: 8px;
        height: 8px;
        border-radius: 2px;
      }
      &.status-action-required {
        &:before {
          content: '';
          background-color: #CD2C1D;
        }
      }
      &.status-complete,
      &.status-clear,
      &.status-consider {
        &:before {
          content: '';
          background-color: #576C82;
        }
      }
      &.status-in-progress {
        &:before {
          content: '';
          background-color: #009BB5;
        }
      }
    }
  }
}