@import 'node_modules/@dashboard-experience/mastodon/lib/theme.scss';

#mastodon .mastodon-container.verification-exception {
  p {
    font-size: 14px;
    line-height: 20px;
  }
  .mastodon-container-row:last-child {
    padding-bottom: 0;
  }
  header.mastodon-container-row {
    h1 {
      font-size: 24px;
      line-height: 29px;
      margin: 0 0 8px;
    }
    p {
      margin: 0;
    }
  }
  .verification-exception__panel {
    display: block;
    h1 {
      background-color: $navy-03;
      color: $white;
      font-size: 14px;
      line-height: 18px;
      padding: 3px 14px;
      margin-bottom: 12px;
    }
    ol {
      padding-inline-start: 20px;
      margin: 0;
    }
  }
  .verification-exception__panel--required {
    h1 {
      background-color: $aqua-04;
    }
  }
  .verification-exception__submit {
    min-width: auto;
  }
}
