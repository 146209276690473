@use 'mastodon-2024-typography/typography.scss';
@import 'node_modules/@dashboard-experience/mastodon/lib/theme.scss';


.icon-text {
  margin-left: 10px;
  vertical-align: top;
}

body, html {
  margin: 0;
  padding: 0;
}

html {
  font-size: 14px;
}

body {
  background-color: #F7F9FB;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}

a {
  color: $checkr-blue;

  &:hover {
    color: $checkr-blue-hover;
  }
}

.company-logo {
  margin-bottom: 20px;
}

@media (max-width: 991px) {
  .company-logo {
    height: 40px;
  }
}

.no-gutter {
  padding:0;
}
@media (max-width: 768px) {
  .no-right-gutter {
    padding-right:3px;
  }
  .no-left-gutter {
    padding-left:3px;
  }
}

.checkr-blue {
  color: $checkr-blue;
  path {
    fill: $checkr-blue;
  }
}

.status-clear {
  color: #5cb85c;
  path {
    fill: #5cb85c;
  }
}

.status-pending {
  color: #f0ad4e;
  path {
    fill: #f0ad4e;
  }
}

.status-consider {
  color: #c06;
  path {
    fill: #c06;
  }
}

.status-suspended,
.status-dispute {
  color: #999;
  path {
    fill: #999;
  }
}

small.timestamp {
  margin-left: 2rem;
}

.well-box {
  background-color: #E0E0E0;
}

.badge {
  color: $white;
  display: inline-block;
  min-width: 72px;
  height: 20px;
  line-height: 20px;
  padding: 0 7px;
  font-size: 12px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-weight: normal;
}

.badge-consider {
  background-color: #c06;
}

.badge-pending {
  background-color: #f0ad4e;
}

.badge-clear {
  background-color: #5cb85c;
}

.badge-complete {
  background-color: #043f93;
}

.badge-suspended, .badge-dispute, .badge-dark {
  background-color: #999;
}

#eta-tooltip {
  fill: #f0ad4e;
}

@media (min-width: 768px) {
  .company-logo {
    height: 60px;
  }

  .card-body {
    padding: 30px;

    &.slim {
      padding-left: 60px;
      padding-right: 60px;
    }
  }

  .card-header {
    padding-left: 30px;
    padding-right: 30px;
  }
}

#mastodon {
  .btn-block {
    display: block;
    width: 100%;
    .mastodon-icon {
      margin-right: 5px;
    }
  }
  .verification__wrapper--new-flow,
  .report__wrapper--new-flow {
    overflow: hidden;
    width: 100%;
    margin: 33px 0 71px;
    .main-container {
      padding: 0 20px;
    }
  }
  .verification__wrapper--new-flow {
    form {
      .mastodon-container {
        &.verification-footer,
        &.verification-exception {
          border: 0;
          @media (min-width: 42rem) {
            padding: 48px;
          }
        }
        &.verification-exception {
          margin-bottom: 32px;
          padding: 48px 12px;
          .mastodon-container-row {
            max-width: inherit;
          }
          @media (min-width: 42rem) {
            padding: 48px;
          }
        }
        &.verification-footer {
          padding: 48px 18px;
          @media (min-width: 42rem) {
            padding: 48px;
          }
        }
        .mastodon-container-row {
          padding-bottom: 12px;
        }
        header.mastodon-container-row {
          display: block;
          padding-bottom: 20px;
          @media (min-width: 42rem) {
            padding-bottom: 28px;
          }
        }
        .mastodon-container-col {
          padding-right: 0 !important;
          margin-bottom: 25px;
          @media (min-width: 42rem) {
            padding-right: 58px !important;
            margin-bottom: 8px;
          }
        }
        .mastodon-container-row + .mastodon-container-row {
          padding-top: 12px;
          &.verification-exception__submit-container {
            padding-top: 0;
            @media (min-width: 42rem) {
              padding-top: 13px;
            }
          }
        }
      }
    }
  }
}

.main-container {
  max-width: 950px;
  margin: 25px auto;
}

.navigation {
  margin-bottom: 30px;
}

.cds--inline-loading {
  &.button-loading-icon {
    min-height: unset;
    height: 21px;
    width: 21px;
    align-items: center;
    justify-content: center;
  }
  &.button-loading-icon--primary {
    .cds--loading__background {
      stroke: $checkr-blue-70 !important;
      fill: $checkr-blue;
    }
    .cds--loading__stroke {
      fill: $checkr-blue;
      stroke: $checkr-blue-10 !important;
    }
  }
  .cds--inline-loading__animation {
    margin: 0;
  }
  svg {
    vertical-align: top !important;
  }
}


.loading {
  margin: auto;
  text-align: center;
  .cds--inline-loading {
    display: inline-block;
    height: auto;
    margin-bottom: .2rem;
    .cds--inline-loading__animation {
      margin: 0;
      .cds--loading--small {
        width: 1.3rem;
        height: 1.3rem;
      }
    }
  }
}

.scrollable {
  max-height: 450px;
  padding:    20px 10px;
  overflow-y: scroll;

  border:        1px solid #ddd;
  border-radius: 3px;
}

.hr-margin {
  margin-top: 30px;
  margin-bottom: 30px;
}

.attached-checkbox {
  margin-top: -10px;
}

.employment-item {
  padding:       20px;
  border:        1px solid #ddd;
  border-radius: 3px;
}

.alert-full-name {
  color: #31708f;
}

.document-upload-header {
  color: $checkr-blue;
  margin-bottom: 16px;
}

.document-upload-header i {
  font-size: 6em
}

.alert-warning {
  border-color: #e2d69c;
}

.card {
  border: none;
  margin-bottom: 1.5rem;
}

.card-grey {
  background-color: #f1f1f1;
}

.card-footer {
  background-color: $white;
}

.card-footer-upload {
  background-color: $white;
  margin-top: -30px;
  padding: 1.25rem;
  text-align: center;

  @media (min-width: 768px) {
    padding-bottom: 40px;
    padding-left: 60px;
    padding-right: 60px;
  }
}

#card-wrapper {
  margin: 20px;
}

.card-additional-text {
  color:#999;
  font-weight: 300;
}

.card.warning {
  border: 1px solid #F1AC43;
  background-color: #FCF8F2;
}

.card.warning .card-header {
  border-bottom: 1px solid #F1AC43;
  background-color: #FCF8F2;
  color: #E8AD4F;
  font-weight: bold;
}

.card-header {
  font-weight: bold;
}

.is-valid .form-control-label {
  color: #292b2c;
}

.is-invalid.form-control {
  border-color: $danger-red-100;
}

.is-invalid.form-control:active,
.is-invalid.form-control:focus {
  border-color: $danger-red-100;
  box-shadow: 0 0 0 3px rgba(220,53,69,.25);
}

.form-control-label {
  margin-top: 16px;
}

.form-control-label.invalid-feedback {
  display: block;
  font-size: 1rem;
  color: $danger-red-100;
  background-color: $white;
  width: auto;
}

.invalid-feedback {
  color: $danger-red-100;
  background-color: $white;
}

.inline-checkbox {
  margin-top: -15px;
  margin-bottom: 0 !important;
}

.inline-checkbox label {
  color: #999;
  margin-bottom: 0;
}

label {
  color: #333;
  font-weight: 300;
}

.custom-text h1 { font-size: 1.5rem; }
.custom-text h2 { font-size: 1.25rem; }
.custom-text h3 { font-size: 1rem; }
.custom-text h4 { font-size: 1rem; }
.custom-text h5 { font-size: 1rem; }
.custom-text h6 { font-size: 1rem; }

/* fix the bottom margin for last <p> tags in custom text */
.custom-text > *:last-child,
.card-body > *:last-child,
.alert > *:last-child {
  margin-bottom: 0;
}

.card-body--icon-panel.top-div-style {
  padding: 48px 18px 84px;
}

/* fix the bottom margin for last row form groups */
@media (min-width: 991px) {
  .card .row:last-child .form-group {
    margin-bottom: 0;
  }
  .multiple-form-group {
    .row:last-child .form-group {
      margin-bottom: 23px;
    }
  }
}

.media {
  padding: 5px 10px;
}
.signature-pad {
  width: 100%;
}

.btn {
  padding: 10px 30px;
  cursor: pointer;
}

.btn-link {
  font-weight: normal;
  color: $checkr-blue;
  border-radius: 0;
}

.btn-clear {
  color: $white;
  background-color: #5cb85c;
}

.btn-consider {
  color: $white;
  background-color: #c06;
}

.btn-secondary {
  color: #292b2c;
  background-color:$white;
  border-color: #ccc;
}

.btn-secondary:hover {
  color: #292b2c;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.btn-secondary:focus, .btn-secondary.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(204, 204, 204, 0.5);
          box-shadow: 0 0 0 2px rgba(204, 204, 204, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #292b2c;
  background-color: $white;
  border-color: #ccc;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #292b2c;
  background-color: #e6e6e6;
  background-image: none;
  border-color: #adadad;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-box {
  min-width: 200px;
}

@media all and (max-width:480px){
   .btn-mobile {
     width: 100%;
     display: block;
   }
}
#mastodon {
  .btn {
    .mastodon-icon {
      color: inherit;
      fill: currentColor;
      margin: 0;
      height: 1.5rem;
      width: 1.5rem;
      vertical-align: top;
    }
  }
  .btn-primary {
    color: $white;
    background-color: $checkr-blue;
    border-color: $checkr-blue;
  }

  .btn-primary:hover {
    color: $white;
    background-color: $checkr-blue-hover;
    border-color: $checkr-blue-hover;
  }

  .btn-primary.disabled, .btn-primary:disabled {
    background-color: $checkr-blue;
    border-color: $checkr-blue;
  }
}

.btn-grey {
  background: #989898;
  border-radius: 3px;
  color: $white;
}

.form-group {
  margin-bottom: 23px;
  &.multiple-form-group {
    margin-bottom: 0;
    > .form-control-label {
      margin-top: 0;
    }
  }
}

.form-control::-moz-placeholder {
  font-weight: 300;
  color: #999;
}

.form-control:-ms-input-placeholder {
  font-weight: 300;
  color: #999;
}

.form-control::-webkit-input-placeholder {
  font-weight: 300;
  color: #999;
}

.form-control:active,
.form-control:focus {
  box-shadow: 0 0 0 3px #d2ecff;
  border-color: $checkr-blue;
}

.is-valid.form-control:active,
.is-valid.form-control:focus {
  box-shadow: 0 0 0 3px #d2ecff;
  border-color: $checkr-blue;
}

.form-control-label {
  margin-bottom: .2rem;
}

.is-valid.form-control {
  border: 1px solid rgba(0,0,0,.15);
}

.identification-upload .form-group {
  margin: 0
}
.card-body .mastodon-icon {
  margin: 0.2rem 0;
  width: 1rem;
  height: 1rem;
  vertical-align: top;
}
@media (max-width: 768px) {
  .location-form input {
    font-size: 0.8em;
    line-height: 1.6em
  }
  .location-form select {
    padding: 0 0 0 1px;
    font-size: 0.8em;
    line-height: 1.6em
  }
}
.selection-results {
  position: relative;
  margin: 1em 0 0;
  padding: 0;
}
.selected-results {
  position: relative;
  margin: 2em 0 0;
}
.selected-results .card {
  margin-bottom: 0 !important;
  border-radius: 0;
}
.location-form .loading,
.selected-results .loading {
  display: none;
  transition: all 0.3s ease-out;
  position: absolute;
  text-align: center;
  margin: 0;
  background-color: rgba(255,255,255,.8);
  top: 0;
  left: 0;
  width: 100%;
  height:100%;
  z-index: 99;
}
.location-form .loading.processing,
.selected-results .loading.processing {
  display: block;
}
.location-form .loading p,
.selected-results .loading p {
  position: relative;
  left:50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.selection-results .card-border {
  border-color: $checkr-blue;
  cursor: pointer;
  transition: all 0.3s ease-out;
}
.selection-results .card-border:hover {
  background-color: $checkr-blue;
}
.selection-results .card-border:hover p,
.selection-results .card-border:hover span {
  color: $white;
}

.selection-results .name, .selection-results .date,
.selected-results .name, .selected-results .date {
  font-weight: bolder;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.selection-results .distance, .selected-results .distance {
  font-size: 0.9em;
  color: #999;
  float: right;
  padding-top: 2px;
}
.selection-results .address, .selection-results .schedule,
.selected-results .address, .selected-results .schedule {
  font-size: 0.9em;
  color: #999;
  margin: 0;
  text-transform: uppercase;
}
.selection-results .phone-number,
.selected-results .phone-number {
  color: #999;
}
.selection-results .hours-abbreviated,
.selected-results .hours-abbreviated {
  color: #999;
  float: right;
  text-transform: uppercase;
}
.selection-results .card-border.selected {
  background-color: $checkr-blue;
}
.selection-results .card-border.selected p,
.selection-results .card-border.selected span {
  color: $white;
}
.selection-results .card-border.selected .hours-abbreviated,
.selected-results .hours-abbreviated {
  display: none;
}
.selection-results .selected-details {
  display: none;
  color: $white;
  margin-top: 10px;
}

.selection-results .selected-details p.hours-full {
  margin: 10px 0 0 0;
}

.selected-results .selected-details {
  margin-top: 10px;
}

.selection-results .card-border.selected .selected-details {
  display: block;
}

.location-form .no-locations {
  margin-top: 20px;
}

.bottom-fixed-menu {
  left: 0;
  width: 100%;
  bottom: 0;
  padding: 1em;
  background-color: $white;
  border-top: solid thin #ddd;
  z-index: 50000;
}

@media (max-width: 768px) {
  .bottom-fixed-menu {
    position: fixed;
  }
}
.bottom-container {
  max-width: 920px;
  margin: 0 auto;
}

.doc-preview {
  margin-right: 10px;
  max-height: 100px;
  border: 1px solid #ccc;
}

.nav-border-bottom {
  margin-bottom: 30px;
  border-bottom: 1px solid #ddd;
}

.nav-border-bottom .container {
  max-width: 950px;
}

.nav-link-custom {
  color: #555555;
  border: 0;
  background-color: inherit;
  font-family: inherit;
}

.nav-link-custom.active {
  font-weight: bold;
  border-bottom: 3px solid $checkr-blue !important;
}

.nav-link-custom:hover {
  color: #555;
  border-bottom: 3px solid #999;
}

a.nav-link-custom:focus {
  color: #555;
  outline: none;
}

.flex-column .nav-item.margin-right {
  margin-right: 0;
}

.nav-item.margin-right {
  margin-right: 30px;
}

.login-error {
  color: #333333;
  border: 1px solid #EBCCCC;
}

.login-error .mastodon-icon {
  fill: #964E72;
  margin: 2px 0 0;
  width: 1rem;
  height: 1rem;
  vertical-align: top;
}

.pull-right {
  float: right;
}

.panel-btn {
  min-width: 180px;
  margin-top: 1rem;
}

.panel-header {
  color: $checkr-blue;
  margin-bottom: 2rem;
}

.panel-description {
  font-weight: 700;
  margin-bottom: 2rem;
}

.panel-info-max-width {
  max-width: 584px;
}

.panel-icon-padding {
  padding-bottom: 20px;
}
.panel-info {
  margin-bottom: 2rem;
}

.panel-info-bold {
  font-weight: bold;
}

.panel-info-bolder {
  font-weight: 700;
}

.panel-details {
  padding: 15px 0;
  border-top: 1px solid #ddd;
}

label.no-bold {
  font-weight: normal;
}

.btn-faq {
  min-width: 120px;
}

.card-plain .card-header {
  background-color: $white;
  border-bottom: 1px solid #ddd;
  color: #333;
}

.card-secondary .card-header {
  background-color: #f5f5f5;
}

.card-pending .card-header {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}

.card-clear .card-header {
  background-color: #dff0d8;
  color: #3c763d;
  border-bottom: 1px solid #d6e9c6;
}

.card-complete .card-header,
.card-submitted .card-header {
  background-color: #d9edf7;
  color: #31708f;
  border-color: #bce8f1;
}

.card-consider .card-header {
  background-color: #fae6f0;
  color: #87114c;
  border-color: #f5cce0;
}

.card-suspended .card-header {
  background-color: #f5f5f5;
  color: #333;
  border-color: #ddd;
}

.card-border {
  border: 1px solid #ddd;
}

.card-consider {
  border: 1px solid #f5cce0;
}

.card-clear {
  border: 1px solid #d6e9c6;
}

.card-complete,
.card-submitted {
  border: 1px solid #ddd;
}

.card-pending {
  border: 1px solid #faebcc;
}

.card-suspended {
  border: 1px solid #ddd;
}

.card-margin {
  margin-bottom: 20px;
}

.card-section {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.table-consider {
  background-color: #fae6f0;
}

.table-summary td {
  border: none;
  padding: 10px 30px;
  border-bottom: 1px solid #eceeef;
}

.table-summary tr:last-child td {
  border-bottom: 0;
}

.table-bottom-bordered tr {
  border: none;
  border-bottom: 1px solid #eceeef;
}

.table-bottom-bordered td {
  border: none;
}

.card-summary .card-body {
  padding: 0;
}

@media all and (max-width:480px){
  .btn-mobile {
    width: 100%;
    display: block;
  }
}

.disclaimer {
  font-weight: bold;
  font-size: 14px !important;
  text-align: justify;
  text-justify: inter-word;
}

.notice {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.notice a {
  color: #0c5460;
  text-decoration: underline;
}

.text-very-muted {
  color: #999;
  font-weight: 300;
}

.text-very-muted a {
  color: #999;
  font-weight: 500;
}

.text-muted {
  color: #999;
}

.grecaptcha-badge {
  display: none;
}

.watermark {
  position: fixed;
  z-index: 1000;
  top: 500px;
  left: 200px;
  opacity: 0.2;
  font-size: 6em;
  -webkit-transform: rotate(-45deg);
}

.text-wrap {
  word-wrap: break-word;
}

td.column-6 {
  width: 50%;
}

#toggle-map-view i, #use-current-location i {
  margin-right: 5px;
}

#map {
  margin-bottom: 10px;
}

#toggle-map-view {
  color: $checkr-blue;
  padding-top: 10px;
  padding-bottom: 10px;
}

.lab-location {
  padding: 0 8px;
  border-radius: 4px;
  color: $checkr-blue;
  border: 2px solid $checkr-blue;
  background-color: $white;
}

.lab-location.active {

  color: $white;
  background-color: $checkr-blue-hover;
}

#toggle-map-view:hover, #use-current-location:not(disabled):hover {
  cursor: pointer;
}

#map .control-panel {
  position: absolute;
  bottom: 20px;
  left: 0;
  max-width: 320px;
  background: $white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.3);
  margin: 20px;
  font-size: 13px;
  line-height: 2;
  color: #6b6b76;
  text-transform: uppercase;
  outline: none;
}

.card-screening-information .status {
  text-transform: capitalize;
}

.card-screening-results.drug-screening-panel table .disposition {
  text-transform: capitalize;
}

.card-screening-results .test-results-header {
  padding: 8px 0;
}

.card-screening-results .screening-results-category:not(:first-child) {
  margin-top: 20px;
}

.card-screening-results .table {
    margin: 0;
}
.card-screening-results .table tr td {
  padding-left: 0;
}
.card-screening-results .table tr td .disposition-indicator {
  margin-left: -16px;
}

.card-screening-results .table tr td .disposition-indicator.text-danger {
  color: #CC0066 !important;
}
.card-screening-results .table tr td .disposition-indicator.text-warning {
  color: #f0ad4e !important;
}

@keyframes placeholder-shimmer{
  0%{
      background-position: -468px 0
  }
  100%{
      background-position: 468px 0
  }
}
.panel-loading {
  width: 100%;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholder-shimmer;
  animation-timing-function: linear;
  background: #f5f5f5;
  background: linear-gradient(to right, #eeeeee 8%, #e5e5e5 18%, #eeeeee 33%);
  background-size: 800px 104px;
  position: relative;
}
.panel-loading.panel-loading-schedule-location {
  height: 127px;
}
.panel-loading.panel-loading-schedule-location-map {
  height: 400px;
}

.additional-info-box {
  background-color: #f5f5f5;
  margin: 20px -10px;
  padding: 10px;
  border-radius: 4px;
}

.additional-info-text {
  font-weight: bold;
  font-size: 12pt;
  padding: 0 0 10px 0;
}

.sensitive-label {
  display: flex;
}
.sensitive-toggle {
  color: $checkr-blue;
  cursor: pointer;
  margin-left: .5rem;
  margin-top: 16px;
}
.sensitive-input {
  letter-spacing: 1px;
}

.info-banner {
  @extend .additional-info-box;
  border: 1px solid $dark-gray-30;
  border-left-width: 4px;
  i {
    margin-right: 5px;
  }
}

.panel-warning-notice {
  background-color: #ffeecc;
  border-left: 4px solid #eb8d00;
}

.notice {
  @extend .panel-warning-notice;
  background-color: #ddd;
  border-left: 4px solid  #aaa;
}

.covid-19-notice, .wrong-person-confirmation {
  @extend .panel-warning-notice
}

.info-panel {
  margin: 20px -10px;
  padding: 1.5%;
  background-color: rgba(245, 245, 245, 0.5);
  box-shadow: inset 8px 0 0 #CCCCCC;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  color: #333333;

  .title {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
  }

  i {
    font-size: 14pt;
    display: inline;
  }
}

.info-panel-icon {
  width: auto;
  margin-bottom: 10px;
}

.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
}

// Copied from bootstrap
.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.text-center {
  text-align: center !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pr-4 {
  padding-right: 1.5rem !important;
}

.pl-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pr-5 {
  padding-right: 3rem !important;
}

.pl-5 {
  padding-left: 3rem !important;
}

@media (min-width: 768px) {
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
}

.mr-auto {
  margin-right: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}
