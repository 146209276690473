@import '@dashboard-experience/mastodon/lib/theme.scss';

#passwordless-login, #email-confirmation {
  width: 100%;

  .email-auth-body {
    margin-bottom: 20px;
    h2 {
      font-size: 42px;
      color: $cool-black-100;
      font-weight: 600;
      margin-bottom: 16px;
      & + p {
        margin-top: 16px;
      }
      @media (min-width: 768px) {
        font-size: 48px;
        line-height: 59px;
        margin-top: 50px;
      }
    }
    p {
      margin: 25px 0;
    }
  }


  p, ul {
    color: $dark-gray-100;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  ul {
    padding-left: 25px;
  }

  li {
    list-style-type: disc;
  }

  .form-text {
    font-size: 12px;
    line-height: 14px;
    margin: 0;
  }

  .title {
    margin: 15px;
  }

  .confirmation-content {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    width: 400px;
    height: 260px;
  }

  .confirmation-content img {
    max-width: 100%;
    max-height: 250px;

    @media (min-width: 768px) {
      max-width: 360px;
    }
  }
}

#passwordless-login {
  margin: auto;

  // the validation errors display was being overwritten by mastadon styles,
  // this is to make sure it displays
  svg {
    z-index: 5;
  }

  .cds--dropdown--invalid {
    button {
      outline: 1px solid $danger-red-100 !important;
      outline-offset: -1px;
    }
  }

  .error-message {
    max-width: 100%;
  }

  .btn {
    min-width: 100%;

    span {
      margin: auto;
    }
  }

  .cds--label {
    margin: .5rem 0;
  }

  .login-info {
    div {
      padding: 28px 1rem 1rem;
    }

    p, span, a {
      font-size: 14px;
      line-height: 24px;
      margin: auto;
    }
  }

  .error {
    color: $danger-red-100;
    font-weight: 400;
    font-size: .75rem;
    margin: 0.25rem 0 0;
  }

  @media (min-width: 768px) {
    width: 515px;
  }
}
