@import 'node_modules/@dashboard-experience/mastodon/lib/theme.scss';

#mastodon {
  .file-uploader {
    width: 100%;
    color: $cool-black-100;
    font-family: $fonts-legacy;
    border: 1px solid rgba($navy-01, .5);
    background-color: #F7F9FB;
    border-radius: 2px;
    overflow: hidden;
    position: relative;
    &.--is-drag-over {
      border-color: red;
    }
    .file-uploader__input-container {
      label {
        width: 100%;
        height: 100%;
      }
    }
    .file-uploader__input {
      width: 0;
      height: 0;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      left: -1px;
      top: -1px;
    }
    .file-uploader__drop-area {
      border: 0;
      padding: 14px;
      width: 100%;
      color: $cool-black-100;
      > span {
        display: block;
        &.file-uploader__label {
          margin-bottom: 1em;
        }
      }
      b {
        font-weight: bold;
      }
      .mastodon-button.secondary {
        &:hover {
          background-color: $light-gray;
          border-color: $checkr-blue-hover;
        }
      }
    }
    .cds--file-browse-btn {
      padding: 13px;
      width: 100%;
      max-width: 100%;
      margin: 0;
      &:hover {
        text-decoration: none;
      }
    }
    .file-uploader--thumbs {
      padding: 13px 0;
      .file-uploader--thumb {
        display: grid;
        grid-template-columns: 2rem minmax(0, 1fr) max-content;
        gap: 12px;
        overflow: hidden;
        .file-uploader--thumb__image {
          max-width: 54px;
          svg {
            fill: $success-green-100;
            width: 100%;
            height: 100%;
          }
        }
        .file-uploader--thumb-description {
          p {
            overflow: hidden;
            margin: 0;
            font-size: 14px;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .thumb-description--type {
            color: $cool-black-40;
          }
        }
        .file-uploader--thumb-remove {
          color: $navy-02;
          text-transform: lowercase;
          font-weight: 400;
          font-size: 12px;
          padding: 10px 16px 10px 10px;
          height: 36px;
          margin-top: -10px;
          > span {
            line-height: 16px;
            height: 16px;
            margin-right: 2px;
          }
          svg.mastodon-icon {
            width: 16px;
            height: 16px;
            fill: $cool-black-100;
          }
        }
      }
    }
    &.file-uploader--error {
      border-color: $danger-red-100;
      .file-uploader__drop-area {
        > span {
          &.file-uploader__error-message {
            margin: 15px 0 0 5px;
            color: $danger-red-100;
            font-size: 12px;
            display: inline-block;
          }
        }
      }
    }
  }
}
