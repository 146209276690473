@import 'node_modules/@dashboard-experience/mastodon/lib/theme.scss';

.tile-option-select {
  background-color: white;
  border: 1px solid #ddd;
  cursor: pointer;
  text-align: left;

  .tile-option-main {
    display: flex;

    .tile-option-value {
      margin-left: auto;
    }
  }
  .tile-option-detail {
    color: $dark-gray-80;
  }
}

.tile-option-select:hover {
  background-color: #f4f4f4;
  border: 1px solid #bbb;
  color: $checkr-blue-hover;
}

.tile-option-multi-select {
  padding-left: 3rem !important;
  background-repeat: no-repeat;
  background-position: 1rem center;
  background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%3Crect%20x%3D%221%22%20y%3D%221%22%20width%3D%2214%22%20height%3D%2214%22%20rx%3D%221%22%20stroke%3D%22%23989898%22/%3E%0A%3C/svg%3E%0A');
}

.tile-option-select-selected {
  border: 1px solid $checkr-blue;
  background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%3Crect%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2215%22%20height%3D%2215%22%20rx%3D%222%22%20fill%3D%22%23043F93%22/%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M6.75%2011.4375L3.625%208.3375L4.61875%207.35625L6.75%209.46875L11.3812%204.875L12.375%205.8625L6.75%2011.4375Z%22%20fill%3D%22white%22/%3E%0A%3C/svg%3E%0A');
}

.tile-option-select-selected:hover {
  border: 1px solid $checkr-blue;
}

.tile-option-footer {
  background-color: #f6f6f6;
}
