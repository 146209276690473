.update-dl-form {

  .update-dl-form-inputs {
    > * {
      width: 50%;

      .cds--select-input__wrapper, .cds--select, select {
        width: 100%;
      }
    }
  }

  .update-dl-form-error {
    margin: 0;
    max-width: inherit;

    .cds--inline-notification__text-wrapper {
      display: block; // This is to workaround a layout bug where the title and subtitle appear on one line if the subtitle isn't long enough.
    }
  }
}
