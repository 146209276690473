@import 'node_modules/@dashboard-experience/mastodon/lib/theme.scss';

#mastodon {
  .mastodon-container.container-default {
    &.verification__welcome {
      width: 100%;
      max-width: 740px;
      padding: 0 20px;
      overflow: hidden;
      border: 0;
      .verification__greeting {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        padding: 22px 0 14px;
        h2 {
          font-family: $fonts-legacy;
          padding: 16px 0;
          color: $navy-04;
          font-size: 36px;
          line-height: 1.176em;
        }
      }
      .verification__body {
        max-width: 490px;
        display: block;
        padding: 0;
        .verification__text {
          font-family: $fonts-legacy;
          color: $navy-04;
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          margin-bottom: 0;
          + .verification__text {
            margin-top: 24px;
          }
        }
        .verification__next-button {
          margin: 24px 0 14px;
        }
      }
      .verification__footer {
        margin: 42px 0 84px;
        padding-top: 42px;
        border-top: 5px solid $paper-01;
        .cds--accordion__item {
          border-top: 0;
          & + .cds--accordion__item {
            margin-top: 24px;
          }
          &:last-child {
            border-bottom: 0;
          }
          .cds--accordion__heading {
            font-family: $fonts-legacy;
            border-bottom: 2px solid $slate-07;
            display: flex;
            align-items: center;
            padding: 0;
            .cds--accordion__title {
              font-size: 16px;
              font-weight: 700;
              color: $navy-02;
              padding: 0;
              padding: 6px 0;
              flex-shrink: 1;
              flex-grow: 1;
            }
            &:hover {
              &::before {
                height: calc(100% + 1px);
                background-color: rgba($navy-02, .03);
              }
            }
            .cds--accordion__arrow {
              width: 100%;
              height: auto;
              flex-shrink: 0;
              flex-grow: 0;
              flex-basis: 16px;
              margin: 0;
            }
          }
          .cds--accordion__content {
            font-family: $fonts-legacy;
            padding-top: 20px;
            padding-bottom: 0;
            p {
              font-size: 15px;
              &.accordion-question,
              &.accordion-answer {
                font-size: 14px
              }
              &.accordion-question {
                font-weight: 700;
                color: $navy-02;
                margin-bottom: 2px;
              }
            }
          }
        }
      }
    }
  }
}
